<template>
<!--  <div class="weui-form-preview" v-if="flag === true">-->
<!--    <div class="weui-form-preview__hd">-->
<!--      <div class="weui-form-preview__item">-->
<!--        <p>店铺信息</p>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="weui-form-preview__bd">-->
<!--      <div class="weui-form-preview__item">-->
<!--        <label class="weui-form-preview__label">商户信息</label>-->
<!--        <span class="weui-form-preview__value">{{DP_Info.Dept_Name}}</span>-->
<!--      </div>-->
<!--      <div class="weui-form-preview__item">-->
<!--        <label class="weui-form-preview__label">社会信用代码</label>-->
<!--        <span class="weui-form-preview__value">{{DP_Info.Credit_Code}}</span>-->
<!--      </div>-->
<!--      <div class="weui-form-preview__item">-->
<!--        <label class="weui-form-preview__label">公司类型</label>-->
<!--        <span class="weui-form-preview__value">{{DP_Info.Company_Type}}</span>-->
<!--      </div>-->
<!--      <div class="weui-form-preview__item">-->
<!--        <label class="weui-form-preview__label">店铺地址</label>-->
<!--        <span class="weui-form-preview__value">{{DP_Info.Company_Address}}</span>-->
<!--      </div>-->
<!--      <div class="weui-form-preview__item">-->
<!--        <label class="weui-form-preview__label">成立日期</label>-->
<!--        <span class="weui-form-preview__value">{{DP_Info.Reg_Date}}</span>-->
<!--      </div>-->
<!--      <div class="weui-form-preview__item">-->
<!--        <label class="weui-form-preview__label">法定代表人</label>-->
<!--        <span class="weui-form-preview__value">{{DP_Info.Legal_representative}}</span>-->
<!--      </div>-->
<!--      <div class="weui-form-preview__item">-->
<!--        <label class="weui-form-preview__label">近三个月投诉</label>-->
<!--        <span class="weui-form-preview__value">{{DP_Info.ts_num}}</span>-->
<!--      </div>-->
<!--      <div style="width: 100%; height: 30px; background-color: #0066e475; margin-top: 10px; color: white; line-height: 30px; text-align: center;">近四周店铺评分记录</div>-->
<!--      <div style="width:100%;background: #fff;overflow-x: scroll" v-html="DP_Info.DPPF" >-->
<!--      </div>-->

<!--    </div>-->
<!--    <div style="margin: 16px;">-->
<!--      <van-button round block type="info" @click="ts()">投诉</van-button>-->
<!--    </div>-->
<!--  </div>-->
<!--  <van-empty image="error" description="无此项目详情，请在后台确认"  v-else/>-->

  <div>
    <div class="layui-row" style="text-align: center;">
    </div>
    <fieldset class="layui-elem-field layui-field-title" style="margin-top: 20px;">
      <legend style="font-weight: 500">店铺信息</legend>
    </fieldset>
    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1">
          <span>商户信息：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input  type="text"  lay-verify="title" autocomplete="off" readonly="readonly" class="layui-input" v-model="DP_Info.Dept_Name" />
        </div>
      </div>
    </div>
    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1">
          <span>社会信用代码：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input  type="text"  lay-verify="title" autocomplete="off" class="layui-input noxg" readonly="true" v-model="DP_Info.Credit_Code" />
        </div>
      </div>
    </div>
    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1">
          <span>公司类型：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input  type="text"  lay-verify="title" autocomplete="off" class="layui-input noxg" readonly="true" v-model="DP_Info.Company_Type" />
        </div>
      </div>
    </div>
    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1">
          <span>店铺地址：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text"  lay-verify="title" autocomplete="off" placeholder="" class="layui-input" v-model="DP_Info.Company_Address" />
        </div>
      </div>
    </div>
    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1">
          <span>成立日期：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input  type="text"  lay-verify="title" autocomplete="off" placeholder="成立日期" readonly="readonly" class="layui-input noxg" v-model="DP_Info.Reg_Date" />
        </div>
      </div>
    </div>
    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1">
          <span>法定代表人：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text" lay-verify="title" autocomplete="off" class="layui-input noxg" readonly="true" v-model="DP_Info.Legal_representative" />
        </div>
      </div>
    </div>
    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1">
          <span>近三个月投诉：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text" lay-verify="title" autocomplete="off" class="layui-input noxg" readonly="true" v-model="DP_Info.ts_num" />
        </div>
      </div>
    </div>
    <div style="width: 100%; height: 30px; background-color: #0066e475; margin-top: 10px; color: white; line-height: 30px; padding-left: 5%;">近四周店铺评分记录</div>
    <div style="width: 99%; border: dashed 2px #c2ccd8;" v-html="DP_Info.DPPF"></div>

    <div style="height: 5px"></div>
        <div style="margin: 16px;">
          <van-button round block type="info" @click="ts()">投诉</van-button>
        </div>
  </div>
</template>

<script >
import { mgop } from "@aligov/jssdk-mgop";
import DES from "@/assets/js/DES";
import {Toast} from "vant";
import Emas from "@/assets/js/Emas";

export default {
name: "index",
  data(){
        return{
          ewm:'',
          token:'1',
          Info:'',
          flag:true,
          DP_Info:{
            // Msg:'1',
            // Dept_Name:'宁波市威尔信息科技有限公司',
            // Credit_Code:'9133028176853339X4',
            // Company_Type:'有限责任公司（自然人投资或控股）',
            // Company_Address:'凤山街道-凤仪路-92号',
            // Reg_Date:'2005/1/17 0:00:00',
            // Legal_representative:'郑煜',
            // DPPF:'<table style=\'width:100%;height:100%;\'><tr><th style=\'text-align:center;\'>评分人</th><th style=\'text-align:center;\'>商铺得分</th><th style=\'text-align:center;\'>评分时间</th></tr><tr><td style=\'text-align:center;\'>朱黄凯</td><td style=\'text-align:center;\'>74</td><td style=\'text-align:center;\'>2021-05-31</td></tr></table>',
            // ts_num:'0'
            Msg:'',
            Dept_Name:'',
            Credit_Code:'',
            Company_Type:'',
            Company_Address:'',
            Reg_Date:'',
            Legal_representative:'',
            DPPF:'',
            ts_num:'0'
          }
        }
  },
  created() {
    this.ewm=this.$route.query.ewm
    this.token=sessionStorage.getItem("token");
    this.fetchData()
    let openid = sessionStorage.getItem("Openid");
    let resname=sessionStorage.getItem("username");
    Emas.getLocationMsg(openid,resname);
  },
  methods:{
    async fetchData(){
      let strData='{"DP_ID":"' + this.ewm + '"}';
      // alert(strData)
      this.Info=DES.encryptDes(strData)
         mgop({
          api: "mgop.will.mqsb.getyhdpinfo", // 必须
          host: "https://mapi.zjzwfw.gov.cn/",
          dataType: "JSON",
          type: "POST",
          data: {token:this.token,type:'1', Info: this.Info },
          appKey: "3n3b3538+2001833223+ekdhqo", // 必须
          onSuccess: (data) => {
            console.log("mgop run success login : " + JSON.stringify(data));
            if (data.data.result == "ok") {
              if (data.data.msg.MSG=="1"){
                    this.DP_Info=data.data.msg
              }
            }else if(data.data.result == "401"){
              Toast.fail('token失效，请重新登录');
              this.$router.push({
                path: '/index'
              })
            }else {
              Toast.fail('当前地址未绑定店铺');
              this.$router.push({
                path: '/index'
              })
            }
          },
          onFail: (err) => {
            console.log("mgop run error. error :  " + JSON.stringify(err));
            Toast.fail('获取数据失败，请重试');
            this.$router.push({
              path: '/index'
            })
          },
        });
    },
     ts(){
       this.$router.push(
           {
             path: '/yhtsAdd',
             query: {
               ewm: this.ewm,
               DP_DZ:this.DP_Info.Company_Address,
               Dept_Name:this.DP_Info.Dept_Name,
             }
           })
    }
  }
}
</script>
<style >
@import "../../assets/css/layui.css";
* {
  margin: 0;
  padding: 0;
  font-family: 'Arial, Helvetica, sans-serif';
}

html,
body {
  height: 100%;
  background: #e9f7ff!important;
}

.grid-demo {
  text-align: right;
}

.layui-input {
  width: 93%;
}

.layui-edge {
  right: 13% !important;
}

.layui-form-select dl {
  min-width: 93%;
}

span {
  line-height: 40px;
}

.layui-row {
  margin-top: 10px;
}

.menu td {
  padding-left: 10px;
}

legend {
  color: #0580AD;
}
</style>